import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { HOST } from '@perpay-web/constants/urls';
import FintechGlobalProviders from '@perpay-web/fintech/components/base/FintechGlobalProviders/FintechGlobalProviders';
import BottomNavContainer from '@perpay-web/fintech/components/containers/BottomNavContainer';
import { ENVIRONMENT } from '@perpay-web/fintech/constants/urls';
import store from '@perpay-web/fintech/settings/configureStore';
import { getCordovaApp } from '@perpay-web/fintech/utils/cordovaUtils';
import { useConstCallback } from '@perpay-web/hooks/useConstCallback';
import { useFullBottomNav } from '@perpay-web/hooks/useFullBottomNavContext';
import { AppRouterProvider } from '@perpay-web/services/router';
// initialize Sentry
import unusedSentry from '@perpay-web/services/sentry';
import { storeUtmCookie } from '@perpay-web/utils/marketingUtils';
import {
    isAndroidAgent,
    isAppBrowserAgent,
    isIosAgent,
} from '@perpay-web/utils/userAgentUtils';

import './assets/images/favicon.png';
// initialize router
import unusedRouter from './components/screens/App/Routes/router';
import './index.scss';

storeUtmCookie(HOST);

// Initialize the cordova scripts early to quickly hide the splash screen
// TODO: should be removed once enough users are no longer on the cordova app */
getCordovaApp().then((CordovaApp) => {
    CordovaApp.initialize(ENVIRONMENT);
});

const NATIVE_CLASSNAME = 'native';
const IOS_CLASSNAME = 'native--ios';
const ANDROID_CLASSNAME = 'native--android';
document.documentElement.classList.toggle(
    NATIVE_CLASSNAME,
    isAppBrowserAgent(),
);
document.documentElement.classList.toggle(IOS_CLASSNAME, isIosAgent());
document.documentElement.classList.toggle(ANDROID_CLASSNAME, isAndroidAgent());

const container = document.getElementById('root');
const root = createRoot(container);

const BottomNav = () => {
    const bottomNavCb = useConstCallback(() => <BottomNavContainer />);
    useFullBottomNav(bottomNavCb);
    return null;
};

root.render(
    <Provider store={store}>
        <FintechGlobalProviders>
            <BottomNav />
            <AppRouterProvider />
        </FintechGlobalProviders>
    </Provider>,
);
