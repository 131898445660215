import { UseFetchCardApplicationStatusDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/useFetchCardApplicationStatusContext';
import { UseSetDeviceInformationDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/useSetDeviceInformationContext';
import { UseSubmitCardApplicationDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/useSubmitCardApplicationContext';
import { UseUpdateUserInfoDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/useUpdateUserInfoContext';
import { useValidateAddressForCardMigrationDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/useValidateAddressForCardMigrationContext';
import { createCombinedProvider } from '@perpay-web/utils/componentUtils';

const DataModuleProviders = createCombinedProvider(
    UseFetchCardApplicationStatusDataModuleProvider,
    UseUpdateUserInfoDataModuleProvider,
    UseSetDeviceInformationDataModuleProvider,
    UseSubmitCardApplicationDataModuleProvider,
    useValidateAddressForCardMigrationDataModuleProvider,
);

export default DataModuleProviders;
