import { Outlet } from 'react-router-dom';

import BorrowerHeaderContainer from '@perpay-web/fintech/components/containers/BorrowerHeaderContainer';
import NotificationsContainer from '@perpay-web/fintech/components/containers/NotificationsContainer';
import { useAppTitle } from '@perpay-web/hooks/useAppTitle';
import { useFullBottomNavRender } from '@perpay-web/hooks/useFullBottomNavContext';
import { useMount } from '@perpay-web/hooks/useMount';
import { useSupportTool } from '@perpay-web/hooks/useSupportTool';
import { useTrackLocationChange } from '@perpay-web/hooks/useTrackLocationChange';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './App.scss';

const App = ({ onMount }) => {
    useMount(onMount);
    useAppTitle();
    const renderedBottomNav = useFullBottomNavRender();

    useTrackLocationChange();
    useSupportTool();

    return (
        <div
            className={getClassName(
                styles.app,
                renderedBottomNav ? styles['has-footer'] : '',
            )}
        >
            <NotificationsContainer />
            <BorrowerHeaderContainer />
            <div className={styles.app__container}>
                <Outlet />
            </div>
            {renderedBottomNav}
        </div>
    );
};

export default App;
