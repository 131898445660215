import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAuthTokens } from '@perpay-web/fintech/actions/authentication';
import LoginForm from '@perpay-web/fintech/components/composite/LoginForm/LoginForm';
import {
    getLoginLoading,
    getErrors,
    getIsExistingUser,
    getIsRecaptchaActive,
    getMFA,
} from '@perpay-web/fintech/selectors/authentication';

const LoginFormContainer = () => {
    const dispatch = useDispatch();
    const errors = useSelector(getErrors);
    const loginLoading = useSelector(getLoginLoading);
    const recaptchaActive = useSelector(getIsRecaptchaActive);
    const mfa = useSelector(getMFA);
    const isExistingUser = useSelector(getIsExistingUser);

    const onSubmit = useCallback(
        (credentials) => dispatch(fetchAuthTokens(credentials)),
        [dispatch],
    );

    return (
        <LoginForm
            onSubmit={onSubmit}
            isLoading={loginLoading}
            errors={errors}
            recaptchaActive={recaptchaActive}
            mfa={mfa}
            isExistingUser={isExistingUser}
        />
    );
};

export default LoginFormContainer;
