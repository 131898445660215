import { lazy } from 'react';

import { withBottomNav } from '@perpay-web/components/higher-order/WithBottomNav/WithBottomNav';
import { withLoader } from '@perpay-web/components/higher-order/WithLoader/WithLoader';
import { withPerpayTitle } from '@perpay-web/components/higher-order/WithPerpayTitle/WithPerpayTitle';
import BorrowerLogout from '@perpay-web/fintech/components/containers/BorrowerLogoutContainer';
import BorrowerResetPassword from '@perpay-web/fintech/components/containers/BorrowerResetPassword';
import LoginFormContainer from '@perpay-web/fintech/components/containers/LoginFormContainer';
import MFAContainer from '@perpay-web/fintech/components/containers/MFAContainer';
import { withLoginRedirectCheck } from '@perpay-web/fintech/components/higher-order/WithLoginRedirectCheck/WithLoginRedirectCheck';
import { withSignupRedirectCheck } from '@perpay-web/fintech/components/higher-order/WithSignupRedirectCheck/WithSignupRedirectCheck';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { importBorrowerLoginContainer } from '@perpay-web/fintech/utils/imports/importBorrowerLoginContainer';
import { importBorrowerSignup } from '@perpay-web/fintech/utils/imports/importBorrowerSignup';
import { importPartnerOnboardingSignupContainer } from '@perpay-web/fintech/utils/imports/importPartnerOnboardingSignupContainer';
import { compose } from '@perpay-web/utils/functionalUtils';

const EmptyBottomNav = () => null;
const BorrowerLoginRoute = compose(
    withPerpayTitle('Login'),
    withLoginRedirectCheck,
    withBottomNav(EmptyBottomNav),
)(withLoader(lazy(importBorrowerLoginContainer)));
const PartnerSignupRoute = compose(
    withPerpayTitle('Sign up'),
    withLoginRedirectCheck,
    withBottomNav(EmptyBottomNav),
)(withLoader(lazy(importPartnerOnboardingSignupContainer)));

const identity = (x) => x;

const BorrowerSignupRoute = compose(
    withPerpayTitle('Sign up'),
    withSignupRedirectCheck,
    withBottomNav(EmptyBottomNav),
    identity,
)(withLoader(lazy(importBorrowerSignup)));

/**
 * @type {import('react-router-dom').DataRouteObject[]}
 */
export const authenticatingRoutes = [
    { path: paths.signUp.path, element: <BorrowerSignupRoute /> },
    {
        path: paths.login.path,
        element: <BorrowerLoginRoute />,
        children: [
            {
                index: true,
                element: <LoginFormContainer />,
            },
            {
                path: paths.resetPassword.path,
                element: <BorrowerResetPassword />,
            },
            {
                path: paths.mfa.path,
                element: <MFAContainer />,
            },
        ],
    },
    { path: paths.logout.path, element: <BorrowerLogout /> },
    {
        path: paths.partnerOnboardingInvitation.path,
        element: <PartnerSignupRoute />,
    },
];
