import { catchError, from, mergeMap, throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import { HTTPMethod } from '@perpay-web/constants/httpMethods';
import { jsonFetch } from '@perpay-web/utils/requestUtils';

import { authentication } from './singleton';

function getAuthorizationHeader() {
    return authentication
        .getRefreshedAccessToken()
        .then((accessToken) => ({
            AUTHORIZATION: `Bearer ${accessToken}`,
        }))
        .catch(() => ({})); // Return empty headers to allow sending anyway.
}

export function authenticatedJsonFetch(
    url,
    body,
    method = HTTPMethod.get,
    headers = {},
) {
    return getAuthorizationHeader().then((authorizationHeader) =>
        jsonFetch(
            url,
            body,
            method,
            {
                ...authorizationHeader,
                ...headers,
            },
            'include',
        ),
    );
}

export function authenticatedAjax(
    url,
    body,
    method = HTTPMethod.get,
    headers = {},
) {
    return from(getAuthorizationHeader()).pipe(
        mergeMap((authorizationHeader) =>
            ajax({
                url,
                body,
                method,
                headers: {
                    ...authorizationHeader,
                    ...headers,
                },
                withCredentials: true,
            }),
        ),
        catchError((result) => throwError(() => result.response)),
    );
}
