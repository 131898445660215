// When PAYROLL_PHONE_HELP_EXPERIMENT_ENABLED is false,
// the entire payroll phone hel experiment is bypassed, and
// no users see the phone help link
export const PAYROLL_PHONE_HELP_EXPERIMENT_ENABLED = false;

// Enable holiday testimonials and other holiday features
export const IS_HOLIDAY_THEME_ENABLED = false;

export const REWARDS_AND_CREDITS_V2_IS_LAUNCHED = false;

export const METAL_CARD_IS_LAUNCHED = true;

// New increased direct deposit screen for RTB users
export const NUDGES_DIRECT_DEPOSIT_SCREEN_IS_LAUNCHED = true;

export const IS_CONSOLIDATED_CARD_FLOW_ACTIVE = !window.PRODUCTION;
