import {
    applyMiddleware,
    configureStore as configureReduxStore,
    compose,
} from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';

import {
    facebookMiddlewareReady,
    segmentMiddlewareReady,
} from '@perpay-web/fintech/actions/analytics';
import { getEpicManager } from '@perpay-web/fintech/epics/epics';
import { getBranchMiddleware } from '@perpay-web/fintech/middleware/branch';
import { getDjangoTrackingMiddleware } from '@perpay-web/fintech/middleware/djangoTracking';
import { getGoogleAnalyticsMiddleware } from '@perpay-web/fintech/middleware/google';
import { sentryReduxEnhancer } from '@perpay-web/fintech/middleware/sentry';
import { getReducerManager } from '@perpay-web/fintech/reducers/reducers';
import rootReducer from '@perpay-web/fintech/reducers/root';
import { configureAbTesting } from '@perpay-web/fintech/settings/configureAbTesting';
import {
    dynamicMiddleware,
    authentication,
} from '@perpay-web/fintech/settings/singletons';
import {
    installFacebookMiddleware,
    installSegmentMiddleware,
} from '@perpay-web/fintech/utils/analyticsUtils';
import {
    get,
    post,
    put,
    patch,
    authenticatedJsonFetch,
    jsonFetch,
} from '@perpay-web/fintech/utils/requestHandlers';

const djangoTrackingMiddleware = getDjangoTrackingMiddleware();
const googleAnalyticsMiddleware = getGoogleAnalyticsMiddleware();
const branchMiddleware = getBranchMiddleware();
const auth$ = authentication.token$;

const epicMiddleware = createEpicMiddleware({
    dependencies: {
        get,
        post,
        put,
        patch,
        authenticatedJsonFetch,
        jsonFetch,
        auth$,
    },
});

const enhancers = compose(
    applyMiddleware(
        djangoTrackingMiddleware,
        googleAnalyticsMiddleware,
        branchMiddleware,
        epicMiddleware,
        dynamicMiddleware.middleware,
    ),
    sentryReduxEnhancer,
);

function configureStore(preloadedState = {}) {
    const reducerManager = getReducerManager();

    const epicManager = getEpicManager();

    const store = configureReduxStore({
        reducer: reducerManager.createRootReducer(rootReducer),
        devTools: !window.PRODUCTION,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ thunk: false }),
        enhancers: (getDefaultEnhancers) =>
            getDefaultEnhancers().concat(enhancers),
    });
    epicMiddleware.run(epicManager.getRootEpic());

    // We dispatch ready actions here to let the analytics epic know to stop buffering events.
    // Since the analytics middleware load async, the events that they track need to be
    // buffered and re-emitted in order to be captured by the middleware after they load.
    // We dispatch in both the resolve and reject callback because we don't care if they were
    // successfully or unsuccessfully loaded, only that they have finished trying to load.
    const dispatchSegmentMiddlewareReady = () =>
        store.dispatch(segmentMiddlewareReady());
    installSegmentMiddleware().then(
        dispatchSegmentMiddlewareReady,
        dispatchSegmentMiddlewareReady,
    );
    const dispatchFacebookMiddlewareReady = () =>
        store.dispatch(facebookMiddlewareReady());
    installFacebookMiddleware().then(
        dispatchFacebookMiddlewareReady,
        dispatchFacebookMiddlewareReady,
    );

    configureAbTesting();

    return store;
}

const store = configureStore();

export default store;
