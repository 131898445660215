import {
    partnerOnboardingSignup,
    verifyPartnerOnboardingCode,
    verifyPartnerOnboardingCodeSuccess,
    completeSignupRequirements,
    createPasswordSuccess,
} from '@perpay-web/fintech/actions/entities/partnerOnboarding';
import { STORE_REPLACE_AUTH } from '@perpay-web/fintech/constants/actionTypes';
import * as flowSteps from '@perpay-web/fintech/constants/steps/cardPartnerOnboardingSteps';
import { getSignupRequirements } from '@perpay-web/fintech/reducers/ui/signup';
import { withReduxStepsReducer } from '@perpay-web/hooks/useReduxSteps';

const initialState = () => ({
    inPartnerOnboardingFlow: false,
    isMagicLinkRedirect: true,
    partnerOnboardingCodeVerificationLoading: false,
    partnerCode: null,
    offerCode: null,
    offerAmount: null,
    // Only verified_phone and password_set are relevant
    // signup requirements for PHO users.
    signupRequirements: getSignupRequirements().filter(
        (r) => r === 'verified_phone' || r === 'password_set',
    ),
});

const partnerOnboardingReducer = (state = initialState(), action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_REPLACE_AUTH: {
            const tokens = payload;
            const partnerOnboarded = tokens.access.payload.is_partner_onboarded;
            const requirements = partnerOnboarded
                ? tokens.access.payload.signup_requirements
                : [];
            // Only verified_phone and password_set are relevant
            // signup requirements for PHO users.
            return {
                ...state,
                signupRequirements: [
                    ...requirements.filter(
                        (r) => r === 'verified_phone' || r === 'password_set',
                    ),
                ],
            };
        }

        case partnerOnboardingSignup().type:
            // user went through regular partner onboarding signup
            // rather than magic link.
            return { ...state, isMagicLinkRedirect: false };

        case verifyPartnerOnboardingCode().type:
            return { ...state, partnerOnboardingCodeVerificationLoading: true };

        case verifyPartnerOnboardingCodeSuccess().type:
            return {
                ...state,
                partnerOnboardingCodeVerificationLoading: false,
                inPartnerOnboardingFlow: true,
                partnerCode: payload.partnerCode,
                offerCode: payload.offerCode,
                offerAmount: payload.offerAmount,
            };

        case createPasswordSuccess().type: {
            return {
                ...state,
                signupRequirements: [
                    ...state.signupRequirements.filter(
                        (i) => i !== 'password_set',
                    ),
                ],
            };
        }

        case completeSignupRequirements().type: {
            return {
                ...state,
                signupRequirements: [],
            };
        }

        default:
            return state;
    }
};

export default withReduxStepsReducer(
    Object.values(flowSteps),
    partnerOnboardingReducer,
);
