import { normalize } from 'normalizr';
import { ofType } from 'redux-observable';
import { mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import {
    fetchDeductionsForDataModule as fetchDeductionsForDataModuleAction,
    fetchDeductionsSuccess,
    fetchDeductionsError,
    replaceDeduction,
    replaceDeductionError,
} from '@perpay-web/fintech/actions/entities/deductions';
import {
    ANALYTICS_PINWHEEL_MODAL_EVENT,
    BACKEND_FETCH_DEDUCTIONS,
    BACKEND_UPDATE_SUCCESS_DEDUCTION,
    BACKEND_UPDATE_SUCCESS_DEDUCTION_ACCOUNT_ID,
    BACKEND_UPDATE_FAILURE_DEDUCTION,
    BACKEND_CREATE_DEDUCTION,
    STORE_REPLACE_DEDUCTION,
    BACKEND_UPDATE_RETRYING_DEDUCTION,
} from '@perpay-web/fintech/constants/actionTypes';
import { PINWHEEL_EVENT_BANKING_INPUT_CONTINUE } from '@perpay-web/fintech/constants/eventTypes';
import {
    PINWHEEL_MODAL_STEP,
    PINWHEEL_STEPS,
} from '@perpay-web/fintech/constants/pinwheelConstants';
import {
    PINWHEEL_SPLIT_MODAL_STEP,
    PINWHEEL_SPLIT_STEPS,
} from '@perpay-web/fintech/constants/pinwheelSplitSetUpPaymentsConstants';
import {
    CARD_ONBOARDING_STEPS,
    PINWHEEL_STEP,
} from '@perpay-web/fintech/constants/steps/cardOnboardingSteps';
import {
    UPDATE_DIRECT_DEPOSIT_STEPS,
    PINWHEEL_STEP as UPDATE_DIRECT_DEPOSIT_PINWHEEL_STEP,
} from '@perpay-web/fintech/constants/steps/updateDirectDepositSteps';
import { DEDUCTIONS } from '@perpay-web/fintech/constants/tableNames';
import { DEDUCTIONS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { deduction } from '@perpay-web/fintech/normalizers/schemas';
import { getDeductionUUID } from '@perpay-web/fintech/selectors/entities/deductions';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';

export function fetchDeductions(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_DEDUCTIONS),
        switchMap(() => get(DEDUCTIONS_ENDPOINT)),
        mergeMap((results) => {
            const normalized = normalize(results.response, [deduction]);
            return [replaceDeduction(normalized.entities[DEDUCTIONS])];
        }),
        handleErrorMessageWithFallback((error) => [
            replaceDeductionError(error),
        ]),
    );
}

export function fetchDeductionsForDataModule(action$, state$, { get }) {
    return action$.pipe(
        ofType(fetchDeductionsForDataModuleAction().type),
        switchMap(() => get(DEDUCTIONS_ENDPOINT)),
        mergeMap((results) => [
            fetchDeductionsSuccess({ all: results.response }),
        ]),
        handleErrorMessageWithFallback((error) => [
            fetchDeductionsError(error),
        ]),
    );
}

export function updateDeduction(action$, state$, { patch }) {
    return action$.pipe(
        ofType(
            BACKEND_UPDATE_SUCCESS_DEDUCTION,
            BACKEND_UPDATE_FAILURE_DEDUCTION,
            BACKEND_UPDATE_RETRYING_DEDUCTION,
            ANALYTICS_PINWHEEL_MODAL_EVENT,
        ),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { payload, type } = action;
            const deductionUUID = getDeductionUUID(state);

            let requestBody = {};
            if (type === ANALYTICS_PINWHEEL_MODAL_EVENT) {
                const { eventName } = payload;

                if (eventName !== PINWHEEL_EVENT_BANKING_INPUT_CONTINUE) {
                    return [];
                }

                // indicates pinwheel "job" was kicked off
                requestBody = {
                    status: 'submitting',
                };
            } else if (type === BACKEND_UPDATE_FAILURE_DEDUCTION) {
                // indicates a failure in the pinwheel modal
                requestBody = {
                    status: 'error',
                    errorDetails: payload,
                };
            } else if (type === BACKEND_UPDATE_RETRYING_DEDUCTION) {
                // This action indicates that the deduction will be retried
                requestBody = {
                    status: 'retrying',
                    errorDetails: payload,
                };
            } else if (type === BACKEND_UPDATE_SUCCESS_DEDUCTION) {
                // indicates success in the pinwheel modal
                requestBody = {
                    status: 'current',
                };

                if (payload.platform) {
                    if (payload.platform.isEmployer) {
                        requestBody.companyKey = payload.platform.name;
                    } else {
                        requestBody.payrollProviderKey = payload.platform.name;
                    }
                }
            }

            return patch(
                `${DEDUCTIONS_ENDPOINT}${deductionUUID}/`,
                requestBody,
            );
        }),
        mergeMap((results) => {
            const normalized = normalize(results.response, deduction);
            return [replaceDeduction(normalized.entities[DEDUCTIONS])];
        }),
        handleErrorMessageWithFallback((error) => [
            replaceDeductionError(error),
        ]),
    );
}

export function updateDeductionAccountId(action$, state$, { patch }) {
    return action$.pipe(
        ofType(BACKEND_UPDATE_SUCCESS_DEDUCTION_ACCOUNT_ID),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { payload } = action;
            const deductionUUID = getDeductionUUID(state);

            const requestBody = { accountId: payload.accountId };

            return patch(
                `${DEDUCTIONS_ENDPOINT}${deductionUUID}/`,
                requestBody,
            );
        }),
        mergeMap((results) => {
            const normalized = normalize(results.response, deduction);
            return [replaceDeduction(normalized.entities[DEDUCTIONS])];
        }),
        handleErrorMessageWithFallback((error) => [
            replaceDeductionError(error),
        ]),
    );
}

export function createDeduction(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_CREATE_DEDUCTION),
        switchMap((action) => {
            const { amount, isPerpaySplit = false } = action.payload;
            const requestBody = {
                amount,
                is_perpay_split: isPerpaySplit,
            };

            return post(DEDUCTIONS_ENDPOINT, requestBody);
        }),

        mergeMap((results) => {
            const normalized = normalize(results.response, deduction);
            return [replaceDeduction(normalized.entities[DEDUCTIONS])];
        }),
        handleErrorMessageWithFallback((error) => [
            replaceDeductionError(error),
        ]),
    );
}

export function pinwheelTransitionToModal(action$, state$) {
    return action$.pipe(
        ofType(BACKEND_CREATE_DEDUCTION),
        switchMap(() =>
            dispatchObservable({
                action$,
                state$,
                waitFor: () => [STORE_REPLACE_DEDUCTION],
                waitForDispatch: [
                    reduxStepsSetStep(PINWHEEL_STEPS, PINWHEEL_MODAL_STEP),
                ],
            }),
        ),
    );
}

export function pinwheelSplitTransitionToModal(action$, state$) {
    return action$.pipe(
        ofType(BACKEND_CREATE_DEDUCTION),
        switchMap(() =>
            dispatchObservable({
                action$,
                state$,
                waitFor: () => [STORE_REPLACE_DEDUCTION],
                waitForDispatch: [
                    reduxStepsSetStep(
                        PINWHEEL_SPLIT_STEPS,
                        PINWHEEL_SPLIT_MODAL_STEP,
                    ),
                ],
            }),
        ),
    );
}

export function cardPinwheelTransitionToModal(action$, state$) {
    return action$.pipe(
        ofType(BACKEND_CREATE_DEDUCTION),
        switchMap(() =>
            dispatchObservable({
                action$,
                state$,
                waitFor: () => [STORE_REPLACE_DEDUCTION],
                waitForDispatch: [
                    reduxStepsSetStep(CARD_ONBOARDING_STEPS, PINWHEEL_STEP),
                ],
            }),
        ),
    );
}

export function updateDirectDepositPinwheelTransitionToModal(action$, state$) {
    return action$.pipe(
        ofType(BACKEND_CREATE_DEDUCTION),
        switchMap(() =>
            dispatchObservable({
                action$,
                state$,
                waitFor: () => [STORE_REPLACE_DEDUCTION],
                waitForDispatch: [
                    reduxStepsSetStep(
                        UPDATE_DIRECT_DEPOSIT_STEPS,
                        UPDATE_DIRECT_DEPOSIT_PINWHEEL_STEP,
                    ),
                ],
            }),
        ),
    );
}
