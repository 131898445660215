import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { getActionFromNative } from '@perpay-web/native-client/actionFromNative';
import { dispatchToNative } from '@perpay-web/native-client/dispatchToNative';

export function actionsToNativeFromWeb(action$) {
    return action$.pipe(
        mergeMap((action) => {
            // Send ALL actions that flow through the action$ Observable
            dispatchToNative(action);

            // Do not re-emit the actions
            return EMPTY;
        }),
    );
}

export function actionsToWebFromNative(action$) {
    // Consume all actions that the nativeAction$ Observable emits
    const actionFromNative$ = getActionFromNative(action$);
    return actionFromNative$;
}
