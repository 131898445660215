import {
    PAY_CYCLE_WEEKLY,
    PAY_CYCLE_BIWEEKLY,
    PAY_CYCLE_MONTHLY,
} from '@perpay-web/fintech/constants/payCycle';
import {
    email,
    hasLowerCase,
    hasMinMaxChars,
    hasNumber,
    hasUpperCase,
    emailOrPhone,
    phoneVerificationCode,
    exactLength,
    requiredNonSpace,
    validateMicroDepositAmount,
    estimatedNetPay,
    ssn,
    dateFormat,
    minDateOfBirth,
    zipCode,
    deserveState,
    deserveCity,
    deserveAddressLineOne,
    deserveAddressLineTwo,
} from '@perpay-web/fintech/validators/fieldBased';
import {
    combined,
    required,
    phoneNumber,
} from '@perpay-web/validators/fieldBased';
import { validateForm } from '@perpay-web/validators/formBased';

export const confirmField =
    (fieldName, confirmFieldName, message) => (values) => {
        if (values[fieldName] !== values[confirmFieldName]) {
            return { [confirmFieldName]: [message] };
        }

        return {};
    };

export const passwordSchema = {
    password: [required, hasLowerCase, hasUpperCase, hasNumber, hasMinMaxChars],
};

export const monthlyEstimatedNetPay = (values) => {
    const schema = {
        estimatedNetPay: [required, estimatedNetPay],
        payCycle: [required],
    };
    const errors = validateForm(values, schema);

    let minMonthlyEstimatedNetPay = null;
    let duration = null;

    // monthly estimated net pay must be greater than $30 to avoid issues assigning spending limits
    switch (values.payCycle) {
        case PAY_CYCLE_WEEKLY:
            // 6.9 = ($30 * 12 months) / 52 weeks
            if (values.estimatedNetPay <= 6) {
                minMonthlyEstimatedNetPay = 6;
                duration = 'week';
            }

            break;
        case PAY_CYCLE_BIWEEKLY:
            // 13.8 = ($30 * 12 months) / 26 weeks
            if (values.estimatedNetPay <= 13) {
                minMonthlyEstimatedNetPay = 13;
                duration = 'two weeks';
            }

            break;
        case PAY_CYCLE_MONTHLY:
            if (values.estimatedNetPay <= 30) {
                minMonthlyEstimatedNetPay = 30;
                duration = 'month';
            }

            break;
        default:
            // default of bi-weekly
            if (values.estimatedNetPay <= 13) {
                minMonthlyEstimatedNetPay = 13;
                duration = 'two weeks';
            }
    }

    if (minMonthlyEstimatedNetPay && duration) {
        errors.estimatedNetPay = [
            `You must enter an amount higher than $${minMonthlyEstimatedNetPay} per ${duration} in order to be assigned a Spending Limit on Perpay.`,
        ];
    }

    return errors;
};

export const phoneVerification = (values) => {
    const errors = {};
    const codeValidators = combined([required, phoneVerificationCode]);

    const codeErrors = codeValidators(values.code);
    if (codeErrors) {
        errors.code = codeErrors;
    }

    return errors;
};

export const loginFormSchema = {
    email_or_phone: [emailOrPhone, required],
    password: [required],
};

export const emailOrPhoneSchema = {
    email_or_phone: [required, emailOrPhone],
};

export const mfaFormSchema = {
    code: [required, exactLength(6, 'digits')],
};

export const signupFormSchema = {
    ...passwordSchema,
    firstName: [required, requiredNonSpace],
    lastName: [required, requiredNonSpace],
    email: [required, email],
};

export const microDepositSchema = {
    amount1: [required, validateMicroDepositAmount],
    amount2: [required, validateMicroDepositAmount],
};

export const setPhoneNumberFormSchema = {
    mobilePhone: [required, phoneNumber],
};

export const verifyPhoneNumberFormSchema = {
    code: [required, phoneVerificationCode],
};

export const personalInformationFormSchema = {
    ssn: [required, ssn],
    dateOfBirth: [required, dateFormat, minDateOfBirth],
};

export const identityVerificationFormSchema = {
    firstName: [required, requiredNonSpace],
    lastName: [required, requiredNonSpace],
    ssn: [required, ssn],
};

export const bankWithMicroDepositSchema = {
    uuid: [required],
    ...microDepositSchema,
};
export const addBankSchema = {
    fullName: [required],
    account: [required],
    routing: [required, exactLength(9, 'digits')],
};

export const addressSchema = {
    street1: [required, deserveAddressLineOne],
    street2: [deserveAddressLineTwo],
    city: [required, deserveCity],
    state: [required, deserveState],
    zipcode: [required, zipCode],
};

export const cardPersonalDetailsSchema = {
    ...addressSchema,
    firstName: [required, requiredNonSpace],
    lastName: [required, requiredNonSpace],
    email: [required, email],
    phoneNumber: [required, phoneNumber],
};

export const cardPersonalDetailsExistingUserSchema = {
    ...addressSchema,
    firstName: [required, requiredNonSpace],
    lastName: [required, requiredNonSpace],
};

export const cardEmploymentDetailsSchema = {
    dateOfBirth: [required, dateFormat, minDateOfBirth],
    ssn: [required, ssn],
    payCycle: [required],
    estimatedNetPay: [required, estimatedNetPay],
};

export const cardEmploymentDetailsPrefilledSchema = {
    payCycle: [required],
    estimatedNetPay: [required, estimatedNetPay],
};
