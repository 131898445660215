import { connect } from 'react-redux';

import { acknowledgeNotification } from '@perpay-web/fintech/actions/entities/notifications';
import Notifications from '@perpay-web/fintech/components/base/Notifications/Notifications';
import { getUnacknowledgedNotifications } from '@perpay-web/fintech/selectors/entities/notifications';

// We do not want to render the Notifications component unless there are notifications to render.
// This allows us to use its onMount to acknowledge the notification.
// This is done here so that the App component doesn't need any knowledge
// about this implementation details.
const NotificationsWrapper = ({ notifications, ...props }) =>
    notifications.length ? (
        <Notifications notifications={notifications} {...props} />
    ) : null;

const mapStateToProps = (state) => {
    const notifications = getUnacknowledgedNotifications(state);

    return {
        notifications,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onAcknowledge: (uuid) => dispatch(acknowledgeNotification(uuid)),
});

// Legacy usage of Redux connect API. New code should not use this pattern

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotificationsWrapper);
