import { SHOP_PREFIX } from '@perpay-web/constants/paths';
import {
    LAST_REACT_STOREFRONT_PAGE,
    REACT_STOREFRONT_HISTORY,
} from '@perpay-web/constants/storage';
import { isAppBrowserAgent } from '@perpay-web/utils/userAgentUtils';

const isMessageLink = (url) =>
    url.startsWith('mailto:') || url.startsWith('sms:');

const isBlobLink = (url) => url.startsWith('blob:');

// Returns whether the provided path is handled by the React app
export const pathIsInternalToApp = (path) => {
    if (!path) {
        return false;
    }

    if (typeof path !== 'string') {
        return false;
    }

    if (isMessageLink(path)) {
        return false;
    }

    if (path.startsWith('http')) {
        return false;
    }

    if (isBlobLink(path)) {
        return false;
    }

    return true;
};

export const pathIsInReactStorefront = (path) =>
    pathIsInternalToApp(path) && path.startsWith(SHOP_PREFIX);

export const getLastReactStorefrontPage = () =>
    window.localStorage.getItem(LAST_REACT_STOREFRONT_PAGE);

export const setLastReactStorefrontPage = (path, query) => {
    let value = path;
    if (query && query.length > 0) value += `${query}`;
    window.localStorage.setItem(LAST_REACT_STOREFRONT_PAGE, value);
};

export const clearLastReactStorefrontPage = () =>
    window.localStorage.removeItem(LAST_REACT_STOREFRONT_PAGE);

export const getAnchorTargetFromUserAgent = () =>
    isAppBrowserAgent() ? '_system' : '_blank';

export const getReactStorefrontHistory = () => {
    if (window.localStorage) {
        return JSON.parse(
            window.localStorage.getItem(REACT_STOREFRONT_HISTORY),
        );
    }

    return null;
};

export const setReactStorefrontHistory = (path, query, isNavigateBack) => {
    const history = getReactStorefrontHistory() || [];
    let value = path;
    if (query && query.length > 0) {
        value += `${query}`;
    }
    const updatedHistory = isNavigateBack
        ? history.slice(0, history.length - 1)
        : [...history, value];

    window.localStorage.setItem(
        REACT_STOREFRONT_HISTORY,
        JSON.stringify(updatedHistory),
    );
};

export const clearReactStorefrontHistory = () =>
    window.localStorage.removeItem(REACT_STOREFRONT_HISTORY);
