import { createAction } from '@reduxjs/toolkit';

import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const partnerOnboardingSignupDataModuleActions = createDataModuleActions(
    'PARTNER_ONBOARDING_SIGNUP',
);
export const partnerOnboardingSignup =
    partnerOnboardingSignupDataModuleActions.dataRequest;
export const partnerOnboardingSignupSuccess =
    partnerOnboardingSignupDataModuleActions.dataSuccess;
export const partnerOnboardingSignupError =
    partnerOnboardingSignupDataModuleActions.dataError;
export const partnerOnboardingSignupReset =
    partnerOnboardingSignupDataModuleActions.dataReset;

export const verifyPartnerOnboardingCode = createAction(
    'backend/GET::VERIFY_PARTNER_ONBOARDING_CODE',
    (partnerCode, offerCode) => ({
        payload: {
            partnerCode,
            offerCode,
        },
    }),
);
export const verifyPartnerOnboardingCodeSuccess = createAction(
    'store/VERIFY_PARTNER_ONBOARDING_CODE::SUCCESS',
);

const partnerOnboardingSubmitCardApplicationDataModuleActions =
    createDataModuleActions('PARTNER_ONBOARDING_SUBMIT_CARD_APPLICATION');
export const partnerOnboardingSubmitCardApplication =
    partnerOnboardingSubmitCardApplicationDataModuleActions.dataRequest;
export const partnerOnboardingSubmitCardApplicationSuccess =
    partnerOnboardingSubmitCardApplicationDataModuleActions.dataSuccess;
export const partnerOnboardingSubmitCardApplicationError =
    partnerOnboardingSubmitCardApplicationDataModuleActions.dataError;
export const partnerOnboardingSubmitCardApplicationReset =
    partnerOnboardingSubmitCardApplicationDataModuleActions.dataReset;

const createPasswordDataModuleActions = createDataModuleActions(
    'PARTNER_ONBOARDING_CREATE_PASSWORD',
);
export const createPassword = createPasswordDataModuleActions.dataRequest;
export const createPasswordSuccess =
    createPasswordDataModuleActions.dataSuccess;
export const createPasswordError = createPasswordDataModuleActions.dataError;
export const createPasswordReset = createPasswordDataModuleActions.dataReset;

export const completeSignupRequirements = createAction(
    'backend/STORE::COMPLETE_SIGNUP_REQUIREMENTS',
);
