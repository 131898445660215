import {
    clearAllBodyScrollLocks,
    disableBodyScroll,
    enableBodyScroll,
} from 'body-scroll-lock';
import FocusTrap from 'focus-trap-react';
import { useEffect, useRef, forwardRef, useCallback } from 'react';

import Portal from '@perpay-web/components/base/Portal/Portal';
import { useEndToEndTestId } from '@perpay-web/hooks/useEndToEndTestId';
import { useMountAndUnmount } from '@perpay-web/hooks/useMountAndUnmount';
import {
    getClassName,
    isInsideScrollableContainer,
} from '@perpay-web/utils/domUtils';
import { isEscapeKey } from '@perpay-web/utils/keyboardUtils';
import { noop } from '@perpay-web/utils/noop';

import styles from './Modal.scss';

/**
 * https://www.w3.org/TR/wai-aria-practices/examples/dialog-modal/dialog.html
 */
const StatelessModal = forwardRef(
    (
        {
            className = null,
            // Legacy multiple classname prop. Do not duplicate this pattern.
            // https://perpay.atlassian.net/wiki/spaces/PERPAY/pages/3282567207/Components+should+own+their+own+styles
            // eslint-disable-next-line @perpay-web/no-multiple-classname-props
            containerClassName = null,
            // Legacy multiple classname prop. Do not duplicate this pattern.
            // https://perpay.atlassian.net/wiki/spaces/PERPAY/pages/3282567207/Components+should+own+their+own+styles
            // eslint-disable-next-line @perpay-web/no-multiple-classname-props
            contentClassName = null,
            // Legacy multiple classname prop. Do not duplicate this pattern.
            // https://perpay.atlassian.net/wiki/spaces/PERPAY/pages/3282567207/Components+should+own+their+own+styles
            // eslint-disable-next-line @perpay-web/no-multiple-classname-props
            closeButtonClassName = null,
            fullscreen = false,
            children,
            showClose = true,
            onClose = null,
            anchorPosition = null,
            keepHeaderAboveOverlay = false,
            allowDismissal = true,
        },
        ref,
    ) => {
        const modalRef = useRef();

        const endToEndTestIDParams = useEndToEndTestId();

        useMountAndUnmount(() => {
            const onClick = (e) => {
                if (!document.getElementById('modal-root')) {
                    // We're in storybook or another non-app context
                    return;
                }

                const targetIsOverlay = e.target.classList.contains(
                    styles.modal__overlay,
                );
                if (
                    document.getElementById('modal-root').contains(e.target) &&
                    !targetIsOverlay
                ) {
                    return;
                }

                if (!document.documentElement.contains(e.target)) {
                    return;
                }

                if (!allowDismissal) {
                    return;
                }

                onClose();
            };

            const onKeyDown = (event) => {
                if (isEscapeKey(event.key) && onClose && allowDismissal) {
                    onClose();
                }
            };

            document.addEventListener('keydown', onKeyDown);
            modalRef.current.addEventListener('click', onClick);

            const modalRefCopy = modalRef.current;

            return () => {
                document.removeEventListener('keydown', onKeyDown);
                if (!modalRefCopy) {
                    return;
                }
                modalRefCopy.removeEventListener('click', onClick);
            };
        });

        return (
            <div
                className={getClassName(
                    styles.modal,
                    styles.modal__overlay,
                    keepHeaderAboveOverlay
                        ? styles['under-header-overlay']
                        : '',
                    fullscreen ? styles['modal--fullscreen'] : '',
                    className,
                )}
                role='dialog'
                aria-modal='true'
                ref={modalRef}
            >
                <div
                    className={getClassName(
                        styles.modal__container,
                        containerClassName,
                    )}
                    style={anchorPosition}
                >
                    <div ref={ref} className={styles.modal__scroll}>
                        <div
                            className={getClassName(
                                styles.modal__content,
                                contentClassName,
                            )}
                        >
                            {onClose && showClose ? (
                                <button
                                    className={getClassName(
                                        styles.modal__close,
                                        closeButtonClassName,
                                    )}
                                    onClick={onClose}
                                    aria-label='Close'
                                    type='button'
                                    {...endToEndTestIDParams}
                                />
                            ) : null}
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    },
);

const Modal = forwardRef((props, ref) => {
    const {
        onClose = null,
        children,
        parentId = window.STORYBOOK ? '' : 'modal-root',
        className = null,
        containerClassName = null,
        contentClassName,
        closeButtonClassName,
        fullscreen = false,
        lock = true,
        showClose = true,
        anchorPosition = null,
        keepHeaderAboveOverlay = false,
        returnFocusOnClose = true,
        allowDismissal = true,
    } = props;
    const modalRef = useRef(null);
    const scrollRef = useRef();
    const childRef = useRef(null);

    useEffect(() => {
        scrollRef.current = modalRef.current;
        if (!ref) {
            childRef.current = modalRef.current;
            return;
        }
        const modifiedRef = ref;
        modifiedRef.current = modalRef.current;
    }, [ref]);

    useEffect(() => clearAllBodyScrollLocks, []);

    const enableScroll = useCallback(() => {
        if (!lock) {
            return;
        }

        if (!scrollRef.current) {
            return;
        }

        enableBodyScroll(scrollRef.current);
    }, [lock]);

    useEffect(() => {
        if (!lock) {
            return noop;
        }

        const scrollElement = scrollRef.current;
        if (!scrollElement) {
            return noop;
        }

        disableBodyScroll(scrollElement, {
            reserveScrollBarGap: true,
            hideBodyOverflow: true,
            allowTouchMove: (element) => {
                if (!scrollElement.contains(element)) {
                    return false;
                }

                if (!isInsideScrollableContainer(element, scrollElement)) {
                    return false;
                }

                return true;
            },
        });

        return () => {
            enableScroll();
        };
    }, [enableScroll, lock]);

    const handleClose = useCallback(() => {
        if (onClose) onClose();
    }, [onClose]);

    return (
        <Portal parentId={parentId}>
            <FocusTrap
                focusTrapOptions={{
                    allowOutsideClick: allowDismissal,
                    // https://github.com/focus-trap/focus-trap/blob/master/docs/js/animated-dialog.js
                    checkCanFocusTrap: (trapContainers) => {
                        const results = trapContainers.map(
                            (trapContainer) =>
                                new Promise((resolve) => {
                                    const interval = setInterval(() => {
                                        if (
                                            window.getComputedStyle(
                                                trapContainer,
                                            ).visibility !== 'hidden'
                                        ) {
                                            resolve();
                                            clearInterval(interval);
                                        }
                                    }, 5);
                                }),
                        );
                        // Return a promise that resolves when all the trap containers are able to receive focus
                        return Promise.all(results);
                    },
                    fallbackFocus: () => document.getElementById('modal-root'),
                    returnFocusOnDeactivate: returnFocusOnClose,
                }}
            >
                <StatelessModal
                    ref={modalRef}
                    onClose={handleClose}
                    parentId={parentId}
                    className={className}
                    containerClassName={containerClassName}
                    contentClassName={contentClassName}
                    closeButtonClassName={closeButtonClassName}
                    fullscreen={fullscreen}
                    showClose={showClose}
                    anchorPosition={anchorPosition}
                    keepHeaderAboveOverlay={keepHeaderAboveOverlay}
                    allowDismissal={allowDismissal}
                >
                    {children}
                </StatelessModal>
            </FocusTrap>
        </Portal>
    );
});

export default Modal;
